import React, { createContext, useReducer, useEffect, useState } from "react"

export const cartWithQuantities = items =>
items.reduce((acc, item) => {

  const found = acc.find(_item => _item.finitura ? (_item.strapiId === item.strapiId) && (_item.colore.trim() === item.colore.trim()) && (_item.finitura.trim() === item.finitura.trim())  : (_item.strapiId === item.strapiId) && (_item.color.trim() === item.color.trim()) )
  if (found) {
    found.quantity = found.quantity + item.quantity
    if (found.quantity === 0) {
      acc = acc.filter(el => el.finitura ? el.strapiId !== item.strapiId || (el.colore.trim() !== item.colore.trim() && el.finitura.trim() !== item.finitura.trim()) : el.strapiId !== item.strapiId || el.color.trim() !== item.color.trim())
    }
  } else {
    item.quantity > 0 && acc.push({ ...item })
  }
  return acc
}, [])

export const addOrReplace = (cart, item) => {
  const found = cart.find(el => el.finitura ? (el.strapiId === item[0].strapiId && (el.colore.trim() === item[0].colore.trim()) && (el.finitura.trim() === item[0].finitura.trim())) : (el.strapiId === item[0].strapiId  && (el.color.trim() === item[0].color.trim())))
  if (found) {
    cart = cart.map(el => (el.finitura ? ((el.strapiId === item[0].strapiId && (el.colore.trim() === item[0].colore.trim()) && (el.finitura.trim() === item[0].finitura.trim())) ? item[0] : el) :
     ((el.strapiId === item[0].strapiId)  && (el.color.trim() === item[0].color.trim()) ? item[0] : el)))
    if (found.quantity === 0 || item[0].quantity === 0) {
      cart = cart.filter(el => (el.finitura ? (el.strapiId !== found.strapiId) || ((el.colore.trim() !== found.colore.trim()) && (el.finitura.trim() !== found.finitura.trim())): (el.strapiId !== found.strapiId) || (el.color.trim() !== found.color.trim())))
    }
  } else {
    cart = [...cart, ...item];
  }
  return cart
}

export let reducer = (committedCart, newCartItem) => {
  if (newCartItem === null && typeof window !== `undefined`) 
 {
      localStorage.removeItem("cart");
      return initialStateCart;
  }
  return addOrReplace(committedCart, newCartItem)
};

export const initialStateCart = []
export const initialStateUser = {...{}, isSameForShipping: true}

const localStateUser = typeof window !== `undefined` ? JSON.parse(localStorage.getItem("user")) : null;
const localStateCart = typeof window !== `undefined` ? JSON.parse(localStorage.getItem("cart")) : null;

const defaultState = {
  cart: cartWithQuantities([]),
  committedCart: [],
  errors: {},
  promoCodes: [],
  cartCount: 0,
  isPromo: false,
  getCartTotal: () => {},
  addQtyToCart: () => {},
  removeQtyFromCart: () => {},
  addToCommittedCart: () => {},
  addQtyToCommittedCart: () => {},
  addVarianteToCommittedCart: () => {},
  addLuceToCommittedCart: () => {},
  removeQtyFromCommittedCart: () => {},
  addToCart: () => {},
  removeItemFromCommittedCart: () => {},
  removeVarianteFromCommittedCart: () => {},
  clearCommittedCart: () => {},
  committedValues: {...{}, isSameForShipping: true},
  clearCommittedValues: () => {},
}

export const StoreContext = createContext(defaultState)

export default ({ children }) => {
  const [promoCodes, setPromoCodes] = useState([]) 
  const [cart, setCart] = useState([]);
  const [committedCart, setCommittedCart] = useReducer(reducer, localStateCart || initialStateCart);
  const [committedValues, setCommittedValues] = useState(localStateUser || initialStateUser);
  const [errors, setErrors] = useState({});
  const [isPromo, setIsPromo] = useState(false)

  useEffect(() => {
    if (typeof window !== `undefined`) 
    {
    localStorage.setItem("cart", JSON.stringify(committedCart));
    localStorage.setItem("user", JSON.stringify(committedValues));
  }}, [committedCart, committedValues]);

  const clearCommittedValues = () => {
      if (typeof window !== `undefined`) { 
        localStorage.removeItem("user"); }
      setCommittedValues(initialStateUser);
    }
    
  const updateItemQuantity = (item, change) => ({
    ...item,
    quantity: (item.quantity || 0) + change,
  })

  const updateItemColorAndQuantity = (item, color, foto, change) => ({
    ...item,
    quantity: (item.quantity || 0) + change,
    color: color.trim(),
    foto
  })

  const updateItemFinishColorAndQuantity = (item, color, finish, foto, change) => ({
    ...item,
    quantity: (item.quantity || 0) + change,
    colore: color.trim(),
    finitura: finish.trim(),
    foto
  })

  const addToCart = item => {
   const newItem = cartWithQuantities(cart).find(el => el.strapiId === item.strapiId)
   if (newItem && newItem.quantity > 0) {
   setCommittedCart([updateItemQuantity(newItem, 0)])}
  }

  const addToCommittedCart = item => {
    let newItem = cartWithQuantities(committedCart).find(el => el.strapiId === item.strapiId)
    newItem = newItem ? updateItemQuantity(newItem, 1) : updateItemQuantity(item, 1)
    setCommittedCart([newItem])
}

const addVarianteToCommittedCart = (item, color) => {
  const foto = item.variante_colore.find(el => el.colore.trim() === color.trim()).foto.url
  let newItem = cartWithQuantities(committedCart).find(el => (el.strapiId === item.strapiId) && (el.colore.trim() === color.trim()))
  newItem = newItem ? updateItemColorAndQuantity(newItem, color.trim(), foto, 1) : updateItemColorAndQuantity(item, color.trim(), foto, 1)
  setCommittedCart([newItem])
}

const addLuceToCommittedCart = (item, color, finish) => {
  const foto = item.foto_luce[0].url
  let newItem = cartWithQuantities(committedCart).find(el => (el.strapiId === item.strapiId) && (el.colore.trim() === color.trim()) && (el.finitura.trim() === finish.trim()))
  newItem = newItem ? updateItemFinishColorAndQuantity (newItem, color.trim(), finish.trim(), foto, 1) : updateItemFinishColorAndQuantity (item, color.trim(), finish.trim(), foto, 1)
  setCommittedCart([newItem])
}

   const addQtyToCart = item => 
    setCart(prevCart => [...prevCart, updateItemQuantity(item, 1)])
  

  const removeQtyFromCart = item => 
    setCart(prevCart => [...prevCart, updateItemQuantity(item, -1)]) 
  

  const addQtyToCommittedCart = item => {
    item = updateItemQuantity(item, 1)
    if (item && item.quantity >= 0)  {
    setCommittedCart([item])}
  }

  const removeQtyFromCommittedCart = item => {
    item = updateItemQuantity(item, -1)
    if (item && item.quantity >=0 ) {
    setCommittedCart([item])}
  }

  const clearCommittedCart = () => setCommittedCart(null)
  
  const removeVarianteFromCommittedCart = (cart, item) => {
    console.log(cart, item)}
    //const found = cart.find(el => el.strapiId === item.strapiId  && (el.color.trim() === item.color.trim()));
    //setCommittedCart([{...found, quantity: 0}])}

    const removeItemFromCommittedCart = item => {
      setCommittedCart([{...item, quantity: 0}])
      const count = item.promo && committedCart.reduce((acc, cur) => cur.promo && (cur.promo.codice === item.promo.codice.toUpperCase()) ? ++acc : acc, 0);
      count === 1 && setPromoCodes(promoCodes.filter(code => code !== item.promo.codice.toUpperCase()))
    }

  const getCartCount = (_cart) =>
    _cart.reduce((acc, item) => {
      return (acc = acc + item.quantity)
    }, 0)

  const getCartTotal = (_cart) =>
    _cart.reduce((acc, item) => {
      return (acc = acc + parseFloat(item.prezzo.toFixed(2)) * item.quantity)
    }, 0)

  return (
    <StoreContext.Provider
      value={{
        cart: cartWithQuantities(cart),
        committedCart: committedCart,
        cartCount: getCartCount(committedCart),
        isPromo,
        setIsPromo,
        promoCodes,
        setPromoCodes,
        errors,
        setErrors,
        getCartTotal,
        addQtyToCart,
        removeQtyFromCart,
        addToCommittedCart,
        addQtyToCommittedCart,
        addVarianteToCommittedCart,
        addLuceToCommittedCart,
        removeQtyFromCommittedCart,
        addToCart,
        removeItemFromCommittedCart,
        removeVarianteFromCommittedCart,
        clearCommittedCart, 
        setCommittedCart,
        committedValues, 
        setCommittedValues, 
        clearCommittedValues
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}