module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#d0d1d9","showSpinner":false,"trickle":false,"minimum":0.4},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-60T7KBSP67","anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"La Murrina Borgomanero","short_name":"lmb","start_url":"/","background_color":"#d0d1d9","theme_color":"#d0d1d9","display":"minimal-ui","icon":"src/images/icon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7947c5c0c8b7e8125a1c2d24f6767677"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
