// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-carrello-js": () => import("./../../../src/pages/carrello.js" /* webpackChunkName: "component---src-pages-carrello-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-gioielli-js": () => import("./../../../src/pages/gioielli.js" /* webpackChunkName: "component---src-pages-gioielli-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oggettistica-js": () => import("./../../../src/pages/oggettistica.js" /* webpackChunkName: "component---src-pages-oggettistica-js" */),
  "component---src-pages-orologi-js": () => import("./../../../src/pages/orologi.js" /* webpackChunkName: "component---src-pages-orologi-js" */),
  "component---src-pages-pronta-consegna-js": () => import("./../../../src/pages/pronta-consegna.js" /* webpackChunkName: "component---src-pages-pronta-consegna-js" */),
  "component---src-templates-legal-template-js": () => import("./../../../src/templates/legalTemplate.js" /* webpackChunkName: "component---src-templates-legal-template-js" */),
  "component---src-templates-murrina-page-template-js": () => import("./../../../src/templates/murrinaPageTemplate.js" /* webpackChunkName: "component---src-templates-murrina-page-template-js" */)
}

